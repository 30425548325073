<ng-container *transloco="let t; read: 'text-to-speech'">
  <ng-container *ngIf="text.trim()">
    <!-- As a single button it is uglier code, but nice ripple animation -->
    <button
      mat-icon-button
      (click)="isSpeaking ? cancel() : play()"
      *ngIf="isSupported"
      [attr.aria-label]="t(isSpeaking ? 'cancel' : 'play')"
      [matTooltipPosition]="'above'"
      [matTooltip]="t(isSpeaking ? 'cancel' : 'play')">
      <mat-icon>{{ isSpeaking ? 'stop' : 'volume_up' }}</mat-icon>
    </button>
    <!-- If language is not supported -->
    <button
      mat-icon-button
      *ngIf="!isSupported"
      [attr.aria-label]="t('unavailable')"
      [matTooltipPosition]="'above'"
      [matTooltip]="t('unavailable')">
      <mat-icon>volume_off</mat-icon>
    </button>
  </ng-container>
</ng-container>
