<mat-toolbar color="primary">
  <mat-icon>add</mat-icon>
  <span>&nbsp;{{ 'translate.title' | transloco }}</span>
  <button
    id="header-map-button"
    mat-icon-button
    (click)="languageSelector.openMap()"
    [attr.aria-label]="'translate.language-selector.more' | transloco">
    <mat-icon svgIcon="switzerland"></mat-icon>
  </button>
  <button
    mat-icon-button
    [attr.aria-label]="'settings.title' | transloco"
    [routerLink]="[{outlets: {settings: ['s'], dialog: ['settings']}}]"
    [queryParamsHandling]="'preserve'">
    <mat-icon>settings</mat-icon>
  </button>
</mat-toolbar>

<div class="container">
  <div id="input-mode-group">
    <app-translate-input-button mode="text" icon="translate"></app-translate-input-button>
    <app-translate-input-button mode="upload" icon="upload"></app-translate-input-button>
  </div>

  <div id="translation-card">
    <div id="language-selector">
      <app-language-selector
        #languageSelector
        [flags]="true"
        [map]="true"
        [hasLanguageDetection]="false"
        [language]="signedLanguage$ | async"
        [languages]="translation.dialects"
        translationKey="translate.dialects"
        urlParameter="di"
        (languageChange)="setSignedLanguage($event)"></app-language-selector>

      <!--        <button-->
      <!--          (click)='swapLanguages()'-->
      <!--          id='swap-languages'-->
      <!--          mat-icon-button-->
      <!--          [disabled]='true'-->
      <!--      [matTooltip]="'translate.swapLanguages' | transloco"-->
      <!--      [attr.aria-label]="'translate.swapLanguages' | transloco"-->
      <!--          <mat-icon>swap_horiz</mat-icon>-->
      <!--        </button>-->
      <button
        id="swap-languages"
        mat-icon-button
        [disabled]="true"
        [matTooltip]="'translate.swapLanguages' | transloco"
        [attr.aria-label]="'translate.swapLanguages' | transloco">
        <mat-icon>arrow_forward</mat-icon>
      </button>
      <app-language-selector
        [hasLanguageDetection]="spokenToSigned"
        [languages]="translation.spokenLanguages"
        translationKey="languages"
        translationScope="languages"
        urlParameter="spl"
        (languageChange)="setSpokenLanguage($event)"></app-language-selector>
    </div>

    <div id="content">
      <app-spoken-to-signed></app-spoken-to-signed>
    </div>
  </div>
  <a id="feedback" href="https://github.com/noe-eva/ubersetzerli/issues" target="_blank">
    {{ 'settings.support.feedback.send' | transloco }}
  </a>
</div>

<!-- Router outlet for the settings dialog -->
<router-outlet name="dialog"></router-outlet>
