<ng-container *ngIf="inputMode$ | async as inputMode">
  <ng-container *ngIf="inputMode === 'upload'">
    <app-upload></app-upload>
  </ng-container>

  <ng-container *ngIf="inputMode === 'text'">
    <ng-container *transloco="let t; read: 'translate.spoken-to-signed'">
      <div class="translation-input">
        <label id="spoken-language-label">{{ t('text') }}</label>
        <textarea
          [formControl]="inputText"
          [maxLength]="maxTextLength"
          dir="auto"
          [lang]="inputLanguage"
          aria-labelledby="spoken-language-label"
          autofocus
          data-gramm_editor="false"></textarea>
        <div>
          <app-speech-to-text [lang]="inputLanguage" (changeText)="inputText.setValue($event)"></app-speech-to-text>
          <app-text-to-speech [lang]="inputLanguage" [text]="inputText.value"></app-text-to-speech>
          <div id="char-count">{{ inputText.value.length | number }} / {{ maxTextLength | number }}</div>
        </div>
      </div>

      <div class="translation-output">
        <div>{{ outputText$ | async }}</div>
        <div>
          <app-text-to-speech [lang]="outputLanguage" [text]="outputText$ | async"></app-text-to-speech>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
