<ng-container *transloco="let t; read: 'translate.language-selector'">
  <mat-tab-group
    (selectedIndexChange)="selectLanguageIndex($event)"
    [(selectedIndex)]="selectedIndex"
    [disablePagination]="true">
    <ng-container *ngIf="hasLanguageDetection">
      <ng-container *ngIf="detectedLanguage$ | async as detectedLanguage; else detectLanguage">
        <mat-tab>
          <ng-template mat-tab-label>
            {{ t('detected', {lang: langNames[detectedLanguage]}) }}
          </ng-template>
        </mat-tab>
      </ng-container>
      <ng-template #detectLanguage>
        <mat-tab [label]="t('detect')"></mat-tab>
      </ng-template>
    </ng-container>
    <mat-tab (select)="selectLanguage(lang)" *ngFor="let lang of topLanguages">
      <ng-template mat-tab-label>
        <app-flag-icon *ngIf="flags" [flag]="lang" [alt]="langNames[lang]"></app-flag-icon>
        {{ langNames[lang] }}
      </ng-template>
    </mat-tab>
  </mat-tab-group>

  <!-- Language button for small screens -->
  <ng-container *ngIf="hasLanguageDetection && detectedLanguage$ | async as detectedLanguage; else detectLanguage">
    <button [matMenuTriggerFor]="signedLanguagesMenu" class="menu-language-button" color="primary" mat-button>
      <app-flag-icon *ngIf="flags" [flag]="detectedLanguage" [alt]="langNames[detectedLanguage]"></app-flag-icon>
      {{ t('detected', {lang: langNames[detectedLanguage]}) }}
    </button>
  </ng-container>
  <ng-template #detectLanguage>
    <button [matMenuTriggerFor]="signedLanguagesMenu" class="menu-language-button" color="primary" mat-button>
      <ng-container *ngIf="language; else noLanguage">
        <app-flag-icon *ngIf="flags" [flag]="language" [alt]="langNames[language]"></app-flag-icon>
        {{ langNames[language] }}
      </ng-container>
      <ng-template #noLanguage>{{ t('detect') }}</ng-template>
    </button>
  </ng-template>

  <!-- Expand icon for large screens -->
  <button
    [matMenuTriggerFor]="signedLanguagesMenu"
    class="menu-icon-button"
    mat-icon-button
    [attr.aria-label]="t('more')">
    <mat-icon>expand_more</mat-icon>
  </button>
  <mat-menu #signedLanguagesMenu class="language-selector-menu">
    <ng-template matMenuContent>
      <button (click)="selectLanguage(null)" mat-menu-item *ngIf="hasLanguageDetection">
        {{ t('detect') }}
      </button>
      <button (click)="selectLanguage(language)" *ngFor="let language of languages" mat-menu-item>
        <app-flag-icon
          *ngIf="flags"
          [flag]="language"
          [alt]="translationKey + '.' + language | transloco"></app-flag-icon>
        {{ langNames[language] }}
      </button>
    </ng-template>
  </mat-menu>
  <button *ngIf="map" class="menu-icon-button" mat-icon-button (click)="openMap()" [attr.aria-label]="t('more')">
    <mat-icon svgIcon="switzerland"></mat-icon>
  </button>
</ng-container>
